import mapempty from './map/mapempty.png'
import church from './map/church.png'
import cellaio from './map/cellaio.png'
import basilica from './map/basilica.png'
import fabric from './map/fabric.png'
import fontana from './map/fontana.png'
import museo from './map/museo.png'
import porta from './map/porta.png'
import portamezzo from './map/portamezzo.png'
import scrittabosco from './map/scrittabosco.png'
import giftw from './gift/giftw.png'
import gift2 from './gift/gift2.png'
import gift3 from './gift/gift3.png'
import gift4 from './gift/gift4.png'
import gift5 from './gift/gift5.png'
import gift6 from './gift/gift6.png'
import giftbg from './gift/giftemp.png'
import rsvptop from './rsvp/rsvptop.png'
import rsvpbot from './rsvp/rsvpbot.png'
import humans from './header/humans.png'
import maintextimg from './header/mainw.png'
import grass1 from './header/grass1.png'
import grass2 from './header/grass2.png'
import grass3 from './header/grass3.png'
import grass4 from './header/grass6.png'
import grass5 from './header/grass5.png'
import alberileft from './header/alberileft.png'
import alberiright from './header/alberiright.png'
import loading from './loading.gif'



const images = 
    {
        map: {
            name: 'mapempty',
            src: mapempty
        },
        mapitems: [
            {
                name: 'church',
                src: church,
                id: 0
            },
            {
                name: 'basilica',
                src: basilica,
                id: 1
            },
            {
                name: 'fabric',
                src: fabric,
                id: 2
            },
            {
                name: 'fontana',
                src: fontana,
                id: 3
            },
            {
                name: 'museo',
                src: museo,
                id: 4
            },
            {
                name: 'porta',
                src: porta,
                id: 5
            },
            {
                name: 'portamezzo',
                src: portamezzo,
                id: 6
            },
            {
                name: 'scrittabosco',
                src: scrittabosco,
                id: 7
            },
            {
                name: 'cellaio',
                src: cellaio,
                id: 8
            }
        ],
        header: [
            {
                name: 'humans',
                src: humans
            },
            {
                name: 'maintext',
                src: maintextimg
            }
        ],
        grass: [
            {   
                id: 0,
                name: 'grass1',
                src: grass1
            },
            {
                id: 1,
                name: 'grass2',
                src: grass2
            },
            {
                id: 2,
                name: 'grass3',
                src: grass3
            },
            {
                id: 3,
                name: 'grass4',
                src: grass4
            },
            {
                id: 4,
                name: 'grass5',
                src: grass5
            },
            {
                id: 5,
                name: 'alberileft',
                src: alberileft
            },
            {
                id: 6,
                name: 'alberiright',
                src: alberiright
            }
        ],
        giftw: {
            name: 'gift',
            src: giftw
        },
        giftbg: {
            name: 'giftbg',
            src: giftbg
        },
        giftitems: [
            {
                id:2,
                src: gift2
            },
            {
                id:3,
                src: gift3
            },
            {
                id:4,
                src: gift4
            },
            {
                id:5,
                src: gift5
            },
            {
                id:6,
                src: gift6
            }
        ],
        rsvp: {
            top: rsvptop,
            bottom: rsvpbot
        },
        loading: loading
    };

export default images;