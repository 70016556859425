import React from 'react'
import './App.css';
import Header from './Header'
import Body from './body/Body'
import Gift from './Gift'
import Rsvp from './rsvp/Rsvp'
import Footer from './Footer'
import gsap from 'gsap/all'
import ScrollTrigger from 'gsap/ScrollTrigger'
import ScrollToPlugin from 'gsap/ScrollToPlugin'

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);

function App() {
  return(
    <>
      <Header />
      <Body />
      <Gift />
      <Rsvp />
      <Footer />
    </>
  )
}

export default App;
