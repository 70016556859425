import React from 'react'
import images from './images'
import * as text from './data.json'
import gsap from 'gsap/all'
import parse from 'html-react-parser'

class Gift extends React.Component {
    componentDidMount() {
        gsap.from('.giftimg', {
            rotate: 5,
            repeat: -1,
            yoyo: true,
            duration: 1,
            ease: 'power2.esasInOut',
            force3D: true,
            stagger: {
                each: 0.5,
                repeat: -1,
                yoyo: true
            }
        })
    }

    render() {
        const gifts = images.giftitems.map(gift => {
            return(
                <img alt="" key={gift.id}className="giftimg" src={gift.src} />
            )
        });
        return(
            <div className="textwrap">
                <div id="giftimage" className="bigimage">
                    <img alt="" src={images.giftbg.src} />
                    <div id="giftw">
                        <img alt="" src={images.giftw.src} />
                    </div>
                    {gifts}
                </div>
                <div id="gift" className="textsection">
                    <p>{parse(text.gift.content)}</p>
                    <p><code>{text.gift.iban}</code></p>
                </div>
            </div>
        )
    }
}

export default Gift;