import React from 'react'
import * as text from '../data.json'

function QuestionText(props) {
    return (
      <div className="questionText">
        {props.content}
      </div>
    )
  }

  function QuestionInput(props) {
      if (props.type === "radio") {
        return (
          <div className="questionInput">
            <div className="radioCont">
              <input type="radio" name={props.name} value="si" onClick={props.click} required></input>
            </div>
            <label htmlFor={props.name}>Sì</label>
            <div className="radioCont">
              <input type="radio" name={props.name} value="no" onClick={props.click} required></input>
            </div>
            <label htmlFor={props.name}>No</label>
          </div>
        )
      } else if (props.type === "text") {
        return (
          <div className="questionInput">
            <input type="text" name={props.name} defaultValue="" required></input>
            <label htmlFor={props.name}></label>
          </div>
        )
      }
  }

  function SubQuestion(props) {
    const name = props.name + 'sub'
    const id = 'subquestion' + props.index;
    if (props.exists) {
      return(
        <div id={id} className="question subquestion">
          <div className="questionText subquestionText">
            {text.quests[props.index].subcontent}
          </div>
          <div className="questionInput subquestionInput">
            <input type="text" name={name}></input>
          </div>
        </div>
      )
    } else {
      return null
    }
  }

class Question extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        subcontent: false
      }
    }

    clickQuestion = (e) => {
      const element = e.target;
      if (element.value === "si" && text.quests[this.props.index].subcontent) {
        this.setState({
          subcontent: true
        });
      } else if (element.value === "no") { 
        this.setState({
          subcontent: false
        })
      }
    }

    render() {
      const index = this.props.index;
      const inputName = "input" + index;
      const isLast = this.props.index === (text.quests.length -1);
      const inputType = isLast ? "text" : "radio";
      const isVisible = this.props.visiblity === index ? 'question visible' : 'question hidden';
      const id = 'question' + index;
        return (
          <div id={id} className={isVisible}>
              <QuestionText 
                key={'qtext' + text.quests[index].id}
                index={index}
                content={this.props.content} 
              />
              <QuestionInput 
                key={'qinput' + text.quests[index].id}
                type={inputType} 
                name={inputName} 
                click={this.clickQuestion}
                subcontent={this.state.subcontent}
              />
              <SubQuestion 
                key={'qsub' + text.quests[index].id}
                exists={this.state.subcontent}
                index={index}
                name={inputName}
              />
          </div>
        )
    }
  }

export default Question;