import gsap from 'gsap/all'
import React from 'react'
import images from '../images'

class Map extends React.Component {
  componentDidMount() {
      gsap.from('.mapitem', {
        scale: 0.9, 
        duration: 1,
        yoyo: true, 
        repeat:-1,
        ease: 'none',
        force3D: true,  
        stagger: {
          each: 0.2,
          repeat: -1,
          yoyo: true,
          ease: 'none'
        }
      })
  }

  render() {
    const mapitems = images.mapitems.map(item => {
      return(
        <img alt="" key={item.id} className="mapitem" id={item.name} src={item.src} />
      )
    })
      return(
          <section id="map" className="bigimage">
            <img alt="" className="mapimg" src={images.map.src}></img>
            {mapitems}
          </section>
        )
  }
}

export default Map;