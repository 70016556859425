import React from 'react'
import * as text from './data.json'

function Footer(props) {
    return (
      <footer>
        {text.credits}
      </footer>
    )
  }

export default Footer;