import React from 'react'
import * as text from '../data.json'
import Question from './Question'
import RsvpNav from './RsvpNav'
import images from '../images'
import gsap from 'gsap'

function Thanks(props) {
  return(
    <p>
      Grazie {props.name}!
    </p>
  )
}

class Rsvp extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        visibility: 0,
        loading: <img alt="" src={images.loading} />
      };
      this.rsvp = React.createRef();
    }

    navQuestions = (e) => {
      e.preventDefault();
      if (e.target.getAttribute('name') === 'next') {
        if (this.state.visibility < (text.quests.length -1)) {
          this.setState({
            visibility: this.state.visibility + 1
          })
        }
      } else if (e.target.getAttribute('name') === 'prev') {
        if (this.state.visibility > 0) {
          this.setState({
            visibility: this.state.visibility - 1
          })
        }
      }
    } 

    submit = (e) => {
      const form = document.getElementById('phpMailerForm'),
      data = new FormData(form);
      e.preventDefault();
      fetch("", {
        method: "POST",
        body: data,
        headers: { 'X-Requested-With' : 'XMLHttpRequest' }
      })
      .then((response) => {
        response.json();
        gsap.to('#phpMailerForm', {autoAlpha:0, 
          onComplete: () => {
            gsap.to('#loadingquestion', {autoAlpha:1})
          }
        });
        setTimeout(() => {
          this.setState({loading: <Thanks name={response.name}/>});
          form.reset();
        }, 2000)
      })
      .catch(error => {
        console.log(error);
      });
    }

    componentDidMount() {
      const rsvp = this.rsvp.current
      gsap.from(rsvp, {scaleY: 0,
        scrollTrigger: {
          trigger: rsvp,
          scrub: 0.5,
          start: 'top bottom',
          end: 'bottom bottom'
        }
      })
    }

    render() {
      const renderQuestions = text.quests.map((question, i) => 
          <Question 
            key={'question' + question.id} 
            index={i} 
            visiblity={this.state.visibility} 
            content={question.content}
            />
      );
      const bgimg = 'url('+images.rsvp.top+'), url('+images.rsvp.bottom+')';
      return (
        <div ref={this.rsvp} id="rsvp" style={{backgroundImage:bgimg}}>
          <form id="phpMailerForm" onSubmit={this.submit}>
            <div id="questionwrap">
              {renderQuestions}
            </div>
            <RsvpNav click={this.navQuestions} 
              visibleQuestion={this.state.visibility}
              />
          </form>
          <div id="loadingquestion">
            {this.state.loading}
          </div>
        </div>
      )
    }
  }

export default Rsvp;