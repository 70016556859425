import React from 'react'
import gsap from 'gsap/all'
import * as text from '../data.json'
import Map from './Map'
import Intro from './Intro'
import parse from 'html-react-parser'

function TextArea(props) {
  return (
    <section id="textarea" className="textsection">
      {parse(props.content)}
    </section>
  )
}

function Navigation(props) {
  const navItems = props.items.map((areas, index) => 
        <NavItem 
          key={areas.id} 
          id={areas.id} 
          value={areas.name} 
          onClick={props.click}
          selected={props.selected}
          />
      )
  return (
    <menu id="navigation">
      {navItems}
    </menu>
  )
}

function NavItem(props) {
  const navId = 'navitem' + props.id;
  const isSelected = props.selected === parseInt(props.id) ? 'navbutton-selected' : '';
  const classe = 'navbutton ' + isSelected;
  return (
    <li className="navitem">
      <button className={classe} onClick={props.onClick} id={navId}>
        {props.value}
      </button>
    </li>
  )
}

class Body extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        id: 0
      };
    }

    handleClick = (e) => {
      const element = e.target;
      const id = element.id.substr(-1);
      const tl = gsap.timeline({
        defaults: {
          duration:0.5
        }
      });
      tl.to('#textarea', {autoAlpha: 0, filter:'blur(10px)', onComplete: () => this.setState({
        id: parseInt(id)
      })},0)
        .to('#textarea', {autoAlpha: 1, filter:'blur(0px)'},0.5)
    }

    resize = () => this.forceUpdate()

    componentDidMount() {
      window.addEventListener('resize', this.resize);
    }

    render() {
        return(
          <>
            <Intro />
            <div id="starter">
              <div id="mainwrap" className="textwrap">
                <Map selected={this.state.id}
                  click={this.handleClick}
                />
                <div id="landwrap">
                <Navigation 
                  items={text.areas} 
                  click={this.handleClick} 
                  selected={this.state.id}
                  />
                <TextArea content={text.areas[this.state.id].content} />
                </div>
              </div>
            </div>
          </>
        )
    }
}

export default Body;