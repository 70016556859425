import React from 'react'
import * as text from '../data.json'

function RsvpNav(props) {
    const number = props.visibleQuestion + 1;
    if (props.visibleQuestion === (text.quests.length - 1)) {
      return (
        <div className="rsvpnav">
          <button type="button" name="prev" onClick={props.click}><i className="fas fa-arrow-circle-left"></i> indietro</button>
          <div id="questionnumber">
            <span>
              {number}
            </span>
          </div>
          <button type="submit" name="submit">invia <i className="fas fa-hand-point-right"></i></button>
        </div>
      )
    } else if (props.visibleQuestion > 0) {
      return (
        <div className="rsvpnav">
          <button type="button" name="prev" onClick={props.click}><i className="fas fa-arrow-circle-left"></i> indietro</button>
          <div id="questionnumber">
            <span>
              {number}
            </span>
          </div>
          <button type="button" name="next" onClick={props.click}>avanti <i className="fas fa-arrow-circle-right"></i></button>
        </div>
      )
    } else {
      return (
        <div className="rsvpnav">
          <button type="button" name="prev" onClick={props.click} disabled><i className="fas fa-arrow-circle-left"></i> indietro</button>
          <div id="questionnumber">
            <span>
              {number}
            </span>
          </div>
          <button type="button" name="next" onClick={props.click}>avanti <i className="fas fa-arrow-circle-right"></i></button>
        </div>
      )
    }
  }

  export default RsvpNav;