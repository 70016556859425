import React from 'react'
import images from './images'


class Header extends React.Component {
    componentDidMount() {
      /*const tl = gsap.timeline({
        defaults: {
          duration: 0.5
        },
        scrollTrigger: {
          trigger: 'header',
              start: 'top top',
              end: '+=500',
              scrub: 0.5,
              pin: true,
              pinSpacing: false
        }
      });
/*
      tl.addLabel('start')
      .to('.headcomponent', {autoAlpha:0},0)
      .to('#grass1', {yPercent: -100, xPercent: 100, autoAlpha:0},0)
      .to('#grass2', {yPercent: -100, xPercent: -100, autoAlpha:0},0)
      .to('#grass3', {yPercent: 100, xPercent: 100, autoAlpha:0},0)
      .to('#grass5', {yPercent: 100, xPercent: -100, autoAlpha:0},0)
      .to('header', {display: 'none'},1) */
    }

    render() {
      const imgs = images.header.map((img, i) => {
        return (
          <div id={img.name} className="headcomponent" key={'headimg' + i}>
            <img alt="" src={img.src} />
          </div>
        )
        }),
        grass = images.grass.map(img => {
          return(
            <img alt="" key={'grass' + img.id} id={img.name} className="grasscomponent" src={img.src} />
          )
        })

      return (
        <>
         <div id="grass">
            {grass}
          </div>
          <header>
            {imgs}
          </header>
        </>
      );
    }
  }

export default Header;