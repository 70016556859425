import React from 'react'
import parse from 'html-react-parser'
import * as text from '../data.json'
import gsap from 'gsap/all'

class Intro extends React.Component {
    componentDidMount() {
        const divs = gsap.utils.toArray('#intro div'),
        tl = gsap.timeline({
            scrollTrigger: {
                trigger: '#intro',
                start: 'top top',
                end: '+=1000',
                snap: {
                    snapTo : 'labels',
                    duration: 0.2,
                    delay: 0.2,
                    ease: 'none'
                    },
                scrub: 0.5,
                pin: true
            }
        }),
        tl2 = gsap.timeline({
            scrollTrigger: {
                trigger: '#starter',
                start: 'top bottom',
                end: 'top top',
                scrub: 0.5
            }
        });
        tl.addLabel('start')
            .to('.headcomponent', {autoAlpha:0},0)
            .to('#grass1', {yPercent: -100, xPercent: 100, autoAlpha:0},0)
            .to('#grass2', {yPercent: -100, xPercent: -100, autoAlpha:0},0)
            .to('#grass3', {yPercent: 100, xPercent: 100, autoAlpha:0},0)
            .to('#grass5', {yPercent: 100, xPercent: -100, autoAlpha:0},0)
            .to('header', {display: 'none'},0)
            .addLabel('label0')
            .from(divs[0], {scale:2, filter:'blur(4px)', autoAlpha:0},0)
            .to(divs, {xPercent: -100},1)
            .from(divs[1], {autoAlpha: 0, scale:2}, 1)
            .addLabel('label1')
            .to(divs, {xPercent: -200},2)
            .from(divs[2], {autoAlpha: 0, scale: 2}, 2)
            .addLabel('label3')
        tl2.to('#intro', {autoAlpha:0, scale:2, overwrite: true, display: 'none'},0)
            
    }
    render() {
        const divs = text.intro.map(div => {
            return(
                <div key={div.id}><p>{parse(div.content)}</p></div>
            )
        })
        return(
            <section id="intro">
                {divs}
            </section> 
        )
    }
}

export default Intro;
